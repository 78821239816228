<template>
  <div v-if="hasLattice">
    <v-row>
      <v-col cols="12" class="mx-n5">
        <div
          v-if="!showSpinner"
          class="plot"
          @click="reactToClick"
          v-html="preview"
        ></div>
      </v-col>
      <v-col cols="12" v-if="showSpinner" class="child-spinner">
        <LoadingIndicator />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h4 class="text mb-7">Plot Settings & Legend</h4>
        <LatticePlotLegend class="mr-3"></LatticePlotLegend>
      </v-col>
    </v-row>

    <div v-if="showErrorMessage" width="750">
      <ValidationErrorAlert
        v-bind:errorMessage="errorMessage"
        v-bind:errorValidateData="errorValidateData"
      ></ValidationErrorAlert>
    </div>
    <v-alert
      type="error"
      outlined
      border="left"
      icon="mdi-cloud-alert"
      prominent
      v-if="errorVisualization != ''"
    >
      {{ errorVisualization }}
    </v-alert>
    <div>
      <v-dialog v-model="dialogSite" max-width="800px" persistent>
        <v-card>
          <v-toolbar flat class="hqsOrange--text text">
            <v-toolbar-title v-model="title">{{ title }} Site</v-toolbar-title>
            <v-spacer />
            <v-btn data-cy="close" class="close" icon @click="closeDialogSite">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <SitesForm
                @closeDialogOnEdit="closeDialogOnEdit"
                v-bind:title="title"
                @dialog="!dialogSite"
              ></SitesForm>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div>
      <v-dialog v-model="dialogBond" max-width="800px" persistent>
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title v-model="title" class="hqsOrange--text"
              >{{ title }} Bond</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn data-cy="close" icon @click="closeDialogBond">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <BondsForm
                @closeDialogOnEdit="closeDialogOnEdit"
                v-bind:title="title"
                @dialog="!dialogBond"
              ></BondsForm>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

const SitesForm = () => import(/* webpackChunkName: "sitesForm" */ './SitesForm')
const BondsForm = () => import(/* webpackChunkName: "bondsForm" */ './BondsForm')
const ValidationErrorAlert = () =>
  import(/* webpackChunkName: "alerts" */ './ValidationErrorAlert')
const LatticePlotLegend = () =>
  import(/* webpackChunkName: "plot" */ './LatticePlotLegend.vue')
const LoadingIndicator = () =>
  import(/* webpackChunkName: "utils" */ '../general/LoadingIndicator')

// eslint-disable-next-line no-unused-vars
const STYLE_TAG_ID = 'lattice-plot-dynamic-styles'
const FALLBACK_COLOR = 'var(--v-secondary-lighten5)'

export default {
  components: {
    LoadingIndicator,
    SitesForm,
    BondsForm,
    ValidationErrorAlert,
    LatticePlotLegend,
  },

  data: () => ({
    showGraph: true,
    showLegend: true,
    errorVisualization: '',
    absolute: true,
    showSpinner: false,
    title: 'Edit',
    preview: '',
    errorMessage: '',
    errorValidateData: '',
    showErrorMessage: false,
    dialogSite: false,
    dialogBond: false,

    bonds: [],
    sites: [],
    nb_circles: [],
    uc_circles: [],

    bondsColors: [],
    sitesColors: [],
  }),

  methods: {
    ...mapActions('latticeBackend', ['validate', 'visualize']),

    ...mapMutations('lattice', [
      'prepareEditSite',
      'prepareEditBond',
      'resetBondID',
      'resetSiteID',
      'setEditBondIndex',
      'resetBondParam',
      'resetSiteParam',
    ]),

    closeDialogOnEdit(title) {
      if (title === 'Edit') {
        this.dialogSite = false
        this.dialogBond = false
      }
    },

    async loadSVG() {
      this.errorVisualization = ''
      this.preview = ''
      if (this.hasLattice) {
        this.showErrorMessage = false
        this.showSpinner = true
        try {
          let svg = await this.visualize(this.getLattice)
          this.preview = svg
        } catch (error) {
          this.errorVisualization =
            'Something went wrong. Could not generate lattice plot. Validate your lattice for more information.'
          this.preview = ''
        }
        this.showSpinner = false
      }
    },

    getBonds(name, num) {
      let class_uc = 'uc_bonds_' + name + '_' + num
      let uc_bonds = document.getElementsByClassName(class_uc)
      let class_nb = 'nb_bonds_' + name + '_' + num
      let nb_bonds = document.getElementsByClassName(class_nb)
      let bonds = this.getChildren(nb_bonds).concat(this.getChildren(uc_bonds))
      return bonds
    },

    getChildren(elements) {
      let n = elements.length
      let result = []
      for (let i = 0; i < n; i++) {
        result[i] = elements[i].children[0]
      }
      return result
    },

    getColorByType(className, type) {
      if (!className) {
        return FALLBACK_COLOR
      }

      const val = className.lastIndexOf('_')

      if (!val) {
        return FALLBACK_COLOR
      }

      const index = className.slice(val + 1)
      const entry = this.getParamColors(type).find(el => el[0] == index)

      if (!entry || !entry.length || !entry[1]) {
        return FALLBACK_COLOR
      }
      
      return entry[1]
    },

    getBondsColors() {
      try {
        let bonds = document.getElementsByTagName('line')

        if (!bonds.length) {
          console.warn('No bond elements have been found. The client probably hasn\'t re-rendered yet.')
          return
        }

        const bondsArray = [...bonds]

        this.bondsColors = bondsArray.map(bond => {
          const className = bond.parentElement.className.baseVal

          if (!className.includes('trans') && this.bondParam !== 'none') {
            return { className, color: this.getColorByType(className, 'bond') }
          } else if (className.includes('trans')) {
            return { className, color: FALLBACK_COLOR }
          } else {
            this.setJValues = false
            return { className, color: FALLBACK_COLOR }
          }
        })
      } catch (e) {
        console.error(e)
      }
    },

    getSitesColors() {
      try {
        const sites = document.getElementsByTagName('circle');

        if (!sites.length) {
          console.warn('No site elements have been found. The client probably hasn\'t re-rendered yet.')
          return
        }

        const sitesArray = [...sites]

        this.sitesColors = sitesArray.map(site => {
          const className = site.parentElement.className.baseVal

          if (this.siteParam === 'none' || this.siteParam === 'default' || this.siteParam === 'magField') {
            return { className, color: FALLBACK_COLOR }
          } else {
            return { className, color: this.getColorByType(className, 'site') }
          }
        })
      } catch (error) {
        console.error(error)
      }
    },

    reactToClick(event) {
      let id = event.target.parentElement.id
      let parentClass = event.target.parentElement.className.animVal
      let val_class = parentClass.lastIndexOf('_')
      let index_class = parentClass.slice(val_class + 1)
      let val_id = id.lastIndexOf('_')
      let index_id = id.slice(val_id + 1)
      if (
        parentClass.startsWith('uc_site') ||
        parentClass.startsWith('cell_')
      ) {
        this.prepareEditSite(index_class)
        this.dialogSite = true
      }
      if (parentClass.includes('intra') || parentClass.includes('inter')) {
        this.prepareEditBond(index_class)
        this.dialogBond = true
      }
      if (id.startsWith('uc_text_')) {
        this.prepareEditSite(index_id)
        this.dialogSite = true
      }
    },

    closeDialogBond() {
      this.dialogBond = false
      this.resetBondID()
    },

    closeDialogSite() {
      this.dialogSite = false
      this.resetSiteID()
    },

    generateDynamicStylesFromColors(nodeColors) {
      if (!nodeColors.length) {
        return
      }

      const styles = nodeColors.map(({ className, color, type }) => {
        return `.plot svg .${className} ${type} { stroke: ${color ?? FALLBACK_COLOR}; fill: ${color ?? FALLBACK_COLOR}; }`
      }).join(' ')

      const styleTag = document.createElement('style')
      const existingStyleTag = document.getElementById(STYLE_TAG_ID)

      if (existingStyleTag) {
        existingStyleTag.remove()
      }

      styleTag.id = STYLE_TAG_ID
      styleTag.innerHTML = styles

      document.body.appendChild(styleTag)
    }
  },

  computed: {
    ...mapState('lattice', {
      hasLattice: (state) => state.hasLattice,
      bondParam: (state) => state.bondParam,
      siteParam: (state) => state.siteParam,
      visibleMagField: (state) => state.visibleMagField,
      updateLattice: (state) => state.updateLattice,
      latticeType: (state) => state.latticeType,
    }),

    ...mapGetters('lattice', ['getLattice', 'getParamColors']),

    nodeColors() {
      return [
        ...this.bondsColors.map(item => ({ ...item, type: 'line' })),
        ...this.sitesColors.map(item => ({ ...item, type: 'circle' }))
      ]
    }
  },

  watch: {
    updateLattice() {
      this.loadSVG()
      this.getBondsColors()
      this.getSitesColors()
    },

    bondParam() {
      this.getBondsColors()
      this.getSitesColors()
    },

    siteParam() {
      this.getBondsColors()
      this.getSitesColors()
    },

    visibleMagField() {
      this.getBondsColors()
      this.getSitesColors()
    },

    nodeColors(newValue) {
      this.generateDynamicStylesFromColors(newValue)
    },
  },

  created() {
    this.loadSVG()
    this.getBondsColors()
    this.getSitesColors()
    this.resetBondParam()
    this.resetSiteParam()
  },
}
</script>

<style>
.plot svg {
  aspect-ratio: 2/1;
}
.plot svg text {
  fill: var(--v-secondary-darken1);
  stroke: var(--v-secondary-darken1);
  font-size: 8px;
  /* 
    This prevents mouse not being able to hover over things properly
    Does not work in IE10 but who cares about that…
  */
  pointer-events: none;
}
.plot svg line {
  fill: var(--v-secondary-lighten5);
  stroke: var(--v-secondary-lighten5);
}
.plot svg circle {
  fill: var(--v-secondary-lighten5);
  stroke: var(--v-secondary-lighten5);
}
.plot svg line,
.plot svg circle {
  cursor: pointer;
  stroke-width: 1px;
  transition:
    fill 150ms ease,
    stroke 150ms ease,
    stroke-width 150ms ease;
}
.plot svg line:hover,
.plot svg g:hover line,
.plot svg circle:hover {
  fill: var(--v-info-darken1) !important;
  stroke: var(--v-info-darken1) !important;
  stroke-width: 3px;
}
.plot * {
  cursor: pointer;
}
.svg-card-container {
  margin-top: 25px;
}
.child-spinner {
  position: relative;
  top: 50%;
  left: 50%;
}
</style>
